import React from 'react'

import Delivery from '../../../images/svgs/delivery-2.svg'
import Garbage from '../../../images/svgs/garbage.svg'
import Calendar from '../../../images/svgs/calendar.svg'

const Comparison = () => (
  <section className="container py-4 my-4 mb-5">
    <div className="row pb-4">
      <div className="col-12 text-center">
        <h2 className="font-weight-bold">Why ELM Foods is different</h2>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-5">
        <h4 className="mb-0 text-center font-weight-bold">Traditional Meal Kits</h4>
      </div>
      <div className="col-2 text-center d-flex flex-column justify-content-center align-items-center">
        <h4 className="font-weight-bold">VS</h4>
      </div>
      <div className="col-5">
        <h4 className="mb-0 text-center font-weight-bold">Shopping With ELM</h4>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-5 d-flex flex-column justify-content-center">
        <p className="mb-0 text-center d-none d-md-block">They lock you into a subscription and charge you if you forget to set your order.</p>
        <p className="mb-0 text-center d-block d-md-none">Inflexible Subscription</p>
      </div>
      <div className="col-2 text-center d-flex flex-column justify-content-center align-items-center">
        <Delivery className="w40p" />
      </div>
      <div className="col-5 d-flex flex-column justify-content-center">
        <p className="mb-0 text-center d-none d-md-block">Our flexible ordering will remind you when it's time to order, but you can order any time.</p>
        <p className="mb-0 text-center d-block d-md-none">Flexible Ordering</p>
      </div>
    </div>
    <div className="row mt-4 bg-">
      <div className="col-5 d-flex flex-column justify-content-center">
        <p className="mb-0 text-center d-none d-md-block">They contain wasteful tiny packaging.</p>
        <p className="mb-0 text-center d-block d-md-none">Wasteful Packaging</p>
      </div>
      <div className="col-2 text-center d-flex flex-column justify-content-center align-items-center">
        <Garbage className="w40p" />
      </div>
      <div className="col-5 d-flex flex-column justify-content-center">
        <p className="mb-0 text-center d-none d-md-block">We deliver full sized groceries. You can use items for multiple meals.</p>
        <p className="mb-0 text-center d-block d-md-none">Full-Sized Groceries</p>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-5 d-flex flex-column justify-content-center">
        <p className="mb-0 text-center d-none d-md-block">They requires ordering a week in advance.</p>
        <p className="mb-0 text-center d-block d-md-none">One Week Notice</p>
      </div>
      <div className="col-2 text-center d-flex flex-column justify-content-center align-items-center">
        <Calendar className="w40p" />
      </div>
      <div className="col-5 d-flex flex-column justify-content-center">
        <p className="mb-0 text-center d-none d-md-block">Free delivery Tuesdays, Thursdays & Sundays.</p>
        <p className="mb-0 text-center d-block d-md-none">Next Day Delivery</p>
      </div>
    </div>
  </section>
)

export default Comparison
