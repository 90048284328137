import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import IndexLayout from '../../layouts/'
import SectionedIngredients from '../../components/shared/SectionedIngredients'
import Testimonials from '../../components/homepage/Testimonials'
import { getAllIngredients } from '../../common/ingredients'
import { CartContext } from '../../components/shared/CartProvider'
import Comparison from '../../components/shared/LandingComponents/Comparison'
import SelectRecipes from '../../components/shared/LandingComponents/SelectRecipes'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Groceries from '../../images/svgs/groceries.svg'
import Recipe from '../../images/svgs/recipe.svg'
import Vegetables from '../../images/svgs/vegetables.svg'

import HowItWorks from '../../components/shared/LandingComponents/HowItWorks'

const recipeSlugs = [
  'tahini-quinoa',
  'bulgur-with-vegetables',
  'grilled-romaine-with-baba-ganoush',
  'smokey-lentils-and-greens',
  'creamy-cashew-pasta',
  'harissa-tofu-toast'
]



const PlantBasedStarter = ({ data }) => {
  const [selectedRecipes, setSelectedRecipes] = useState([])
  const [filterRecipe, setFilterRecipe] = useState(null)

  const recipes = data.allContentfulRecipe.nodes.filter(recipe => recipeSlugs.indexOf(recipe.slug) != -1)
  const setFilter = (recipe) => {
    if (recipe == filterRecipe) {
      setFilterRecipe(null)
    } else {
      setFilterRecipe(recipe)
    }
  }
  const allIngredients = getAllIngredients(recipes.filter(recipe => selectedRecipes.indexOf(recipe.slug) != -1), filterRecipe)

  return (
    <IndexLayout>
      <div className="section header bg-light">
        <div className="container bg-light pt-5">
          <div className="header-index bg-light mb-0 pb-1">
            <div className="row">
              <div className="col-12 col-md-7 py-4 d-flex flex-column justify-content-center">
                <h1 className="font-weight-bold display-1">
                  Start cooking plant-based meals with ELM
                </h1>
                <h4>
                  Everything you love about meal kits, none of the headaches
                </h4>
                <a className={`btn btn-lg btn-primary mt-3 mr-5 d-print-none mb-5`}
                  onClick={() => scrollTo('#pick-recipes')}>
                  Get Started
                </a>
              </div>
              <div className="col-12 col-md-4">
                <img className="img-fluid bowl" alt="Eating Plants" src={data.groceryImage.childImageSharp.fluid.src} />
              </div>
            </div>
          </div>
        </div>
      </div>
      < Comparison />
      <HowItWorks className="mt-3" cellData={[
        { icon: Recipe, text: "Choose a few recipes you want to try out", header: "Recipes" },
        { icon: Vegetables, text: "We’ll show you what you need to make them", header: "Ingredients" },
        { icon: Groceries, text: "Select what you need and pick a day and time for delivery", header: "Delivered" }
      ]} />
      <SelectRecipes recipes={recipes} setRecipes={setSelectedRecipes} />
      <div className="container pt-4">
        <div className="row">
          <div className="col-12">
            <h2 className="font-weight-bold">2 - Shop for ingredients</h2>
            {selectedRecipes.length == 0 && <p className="small">Select a few recipes above to get started</p>}
            {selectedRecipes.length == 1 && <p className="small">Everything you need to make this recipe. Select what you need below.</p>}
            {selectedRecipes.length > 1 && <p className="small">Everything you need to make these {selectedRecipes.length} recipes. Select what you need below.</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="smaller font-weight-bold mb-2">Tap a recipe below to view only it's ingredients</p>
          </div>
        </div>
        <div className="row scroll-horizontal-md border-top">
          {recipes.filter(recipe => selectedRecipes.indexOf(recipe.slug) != -1).map((recipe, index) => {
            const selected = filterRecipe === recipe

            return (
              <div className={`cursor-pointer col-5 col-sm-3 col-xl-2 rounded py-2 text-center ${selected ? 'bg-dark' : ''}`} key={`filter-recipe-${index}`} onClick={() => {
                setFilter(recipe)
              }}>
                <img className="img-fluid" src={recipe.rectangularImage?.fluid?.src} alt="The recipe" />
                <div style={{ height: "60px" }}>
                  <div className="smaller font-weight-bold pt-1 white-space-wrap limit-lines-two">{recipe.name}</div>
                </div>
              </div>
            )
          })}
        </div>
        <SectionedIngredients ingredients={allIngredients} />
      </div>
      <section className="pick-ingredients">
        <div className="container">

          <CartContext.Consumer>
            {(cart) => {
              return (
                <div className="row pt-4">
                  <div className="col-8 col-md-4 mx-auto text-center">
                    {cart.contents.length > 0 && <>
                      <button className="btn btn-primary btn-block" onClick={cart.toggleCart}>Checkout</button>
                      <p className="smaller">{'\u00A0'}</p>
                    </>
                    }
                    {cart.contents.length == 0 && <>
                      <button className="btn btn-primary btn-block disabled">Checkout</button>
                      <p className="smaller">Add some items to your cart</p>
                    </>
                    }
                  </div>
                </div>
              )
            }}
          </CartContext.Consumer>
        </div>
      </section>
      <Testimonials />
    </IndexLayout >
  )
}

export const query = graphql`
  query {
    groceryImage: file(relativePath: { eq: "images/Ingredients.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        rectangularImage {
          file {
            url
          }
          fluid {
            src
          }
        }
        ingredients {
          amount
          ingredient {
            name
            sku
            priceCents
            id
            section
            quantityDescription
            brand
            picture {
              fluid(quality: 100, maxWidth:150) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default PlantBasedStarter
